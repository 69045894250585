import { useQuery } from 'react-query';

import { useClientRender } from '~/hooks/use-client-render';

import { getExperimentVariantOnClient } from '../utils';

const flagKey = 'qualified-chat-bot-50-50';

export function useChatBotExperiment() {
  const isClient = useClientRender();

  const { data: variant } = useQuery('qualifiedChatBot', () => getExperimentVariantOnClient({ flagKey }), {
    enabled: isClient,
  });

  return variant;
}
